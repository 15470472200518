import { createContext } from "@lit/context";

import type { Config, CurrentBrand, LicenseSummary, SessionUser } from "@goauthentik/api";

export const EMS.HOUSEConfigContext = createContext<Config>(Symbol("authentik-config-context"));

export const EMS.HOUSEUserContext = createContext<SessionUser>(Symbol("authentik-user-context"));

export const EMS.HOUSEEnterpriseContext = createContext<LicenseSummary>(
    Symbol("authentik-enterprise-context"),
);

export const EMS.HOUSEBrandContext = createContext<CurrentBrand>(Symbol("authentik-brand-context"));

export default EMS.HOUSEConfigContext;
